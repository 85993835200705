.full-width-header .rs-header .menu-area{background-image: linear-gradient(90deg, #FB6300 0%, #e29b6b 100%);  }

.btn1{background-image: linear-gradient(90deg, #FB6300 0%, #e29b6b 100%); 
    padding: 16px 40px 16px 40px; 
    font-weight: 500; font-size: 16px; border: none;
    border-radius:30px 30px 30px 30px; color: #ffffff;
}
.rs-contact .contact-box{background-image: linear-gradient(90deg, #FB6300 0%, #e29b6b 100%);  }
.readon.learn-more.submit{background-image: linear-gradient(90deg, #FB6300 0%, #e29b6b 100%);  }
.rs-process .rs-addon-number .number-text .number-area{color: #FB6300;}
.rs-process .process-wrap{background: #FB6300;}